// import type { ThemeConfiguratorProps } from '@/components/template/ThemeConfigurator'
// import ThemeConfigurator

import Button from "@/shared/Button/Button";
import SwitchDarkMode2 from "@/shared/SwitchDarkMode/SwitchDarkMode2";
import { IoMdClose } from "react-icons/io";
import { Switch } from "@headlessui/react";
import Select from "react-select";
import { useCallback, useEffect, useState } from "react";
import Cookies from "js-cookie";

interface Props {
  setIsOpen: any;
}
const modes = ["light", "dark", "system"];
const modeOptions = modes.map((mode) => ({ label: mode, value: mode }));
const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

const SidePanelContent = ({ setIsOpen }: Props) => {
  // const isDark=localStorage.getItem('theme')
  const [isMode, setIsMode] = useState(Cookies.get("theme") || "system");

  const handleSystemMode = useCallback(() => {
    if (mediaQuery.matches) {
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    } else {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    }
  }, []);

  useEffect(() => {
    if (isMode === "system") {
      mediaQuery.addEventListener("change", handleSystemMode);
      handleSystemMode();
      return () => {
        mediaQuery.removeEventListener("change", handleSystemMode);
      };
    } else {
      mediaQuery.removeEventListener("change", handleSystemMode);
      if (isMode === "dark") {
        document.body.classList.add("dark");
        document.body.classList.remove("light");
      } else if (isMode === "light") {
        document.body.classList.add("light");
        document.body.classList.remove("dark");
      }
    }
  }, [isMode, handleSystemMode]);

  const handleChange = (e: any) => {
    const mode = e?.target.value;
    Cookies.set("theme", mode);
    setIsMode(mode);
    if (mode === "dark") {
      document.documentElement.classList.remove("light");
    } else if (mode === "light") {
      document.documentElement.classList.remove("dark");
    } else {
      if (mediaQuery.matches) {
        document.documentElement.classList.add("dark");
        document.documentElement.classList.remove("light");
      } else {
        document.documentElement.classList.add("light");
        document.documentElement.classList.remove("dark");
      }
    }
  };

  return (
    <>
      <div className="dark:bg-gray-700 flex items-center justify-between px-6 py-4">
        <div className="flex flex-col ">
          <h3 className="dark:text-gray-100">Choose your mode</h3>
          <span className="dark:text-gray-100 text-xs">
            Customize the appearance by changing the mode
          </span>
        </div>
        <div
          onClick={() => {
            setIsOpen(false);
          }}
          className="dark:text-gray-100 p-2 rounded-full cursor-pointer"
        >
          <IoMdClose />
        </div>
      </div>
      <div className="flex flex-col h-full justify-between dark:bg-gray-700 px-6 pb-6">
        <div className="flex flex-col gap-y-10 mb-6">
          <div className="flex items-center justify-between">
            <div>
              {modes.map((mode) => (
                <label
                  key={mode}
                  className="flex items-center p-2 rounded-md cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors"
                >
                  <input
                    type="radio"
                    value={mode}
                    checked={isMode === mode}
                    onChange={handleChange}
                    className="mr-2 accent-indigo-600"
                  />
                  <span className="dark:text-gray-100">{mode}</span>
                </label>
              ))}
            </div>
          </div>
          {/* <div className="flex items-center justify-between">
                    <div>
                        <h6>Direction</h6>
                        <span>Select a direction</span>
                    </div>
                    <DirectionSwitcher callBackClose={callBackClose} />
                </div> */}
          {/* <div>
                    <h6 className="mb-3">Nav Mode</h6>
                    <NavModeSwitcher />
                </div> */}
          {/* <div>
                    <h6 className="mb-3">Theme</h6>
                    <ThemeSwitcher />
                </div> */}
          {/* <div>
                    <h6 className="mb-3">Layout</h6>
                    <LayoutSwitcher />
                </div> */}
        </div>
        {/* <CopyButton /> */}
      </div>
    </>
  );
};

export default SidePanelContent;
