
import { Controller,useFormContext, UseFormSetValue, UseFormGetValues  } from 'react-hook-form'; // Assuming you're using react-hook-form
import Select from "react-select";// Replace with actual imports from your component library
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { State } from "country-state-city";
import React, { useMemo, useState,useEffect, Dispatch, SetStateAction } from "react";
import Cookies from 'js-cookie';
interface BillingAddressFormProps {
  control: any; // Replace with the type for your form control
  errors: any;
  setValue: any;
 
  clearErrors: any;
  namespace: any;
  billingCity: any;
  billingAddressInfo: any;
  billingZip: any;
  billingState: any;
  billingCountry: any;
  // setFormValue:Dispatch<SetStateAction<any>>
}
  
const BillingAddressForm: React.FC<BillingAddressFormProps> = ({
  control,
  errors,
 
  setValue,
  billingCity,
  billingAddressInfo,clearErrors,billingZip,billingState,billingCountry,
  namespace,
}) => {

  const isDark =Cookies.get('theme')
 
  const formatOptionLabel = ({ label, value }: any, { context }: any) => {
    return context === "value" ? value : label;
  };
  const stateOption = useMemo(() => {
    return State.getAllStates()
      .filter((code: any) => code.countryCode === "US")
      .map((state: any) => ({
        label: state.name,
        value: state.isoCode,
      }));
  }, []);
console.log(isDark,"isdark")
  const [formValue, setFormValue] = useState({});
  const hanldeChange = (e: any) => {
    const { name, value } = e?.target;
    setFormValue((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    
    Cookies.set(
      "BillingAddress",
      JSON.stringify({ ...formValue, [name]: value })
    );
  };
  useEffect(() => {
    if (billingCity) {
      setValue(`${namespace}.city`, billingCity);
    }
    if (billingAddressInfo) {
      setValue(`${namespace}.address1`, billingAddressInfo);
    }
    if (billingZip) {
      setValue(`${namespace}.zip`, billingZip);
    }
    if (billingState) {
      setValue(`${namespace}.state`, billingState);
    }
    if(billingCountry){
      setValue(`${namespace}.country`, billingCountry);
    }
  }, [billingCity, control, namespace, billingAddressInfo, billingZip, billingState,billingCountry]);
  return (
    <div className={`mt-6 mb-4 space-y-3 sm:space-y-5`}>
      <h1 className="text-1xl font-bold">Billing Address</h1>
      <div className="max-w-lg">
        <Label className="text-sm"><span className="text-red-500">*</span>  Address 1</Label>
        <Controller
          name={`${namespace}.address1`}
          control={control}
          rules={{
            required: "Address is Required",
          }}
          render={({ field }) => (
            <Input
              {...field}
              className="mt-1.5"
              onInput={(e: any) => hanldeChange(e)}
            />
          )}
        />
        {errors[`${namespace}`]?.address1 && (
          <span className="text-red-500">
            {errors[`${namespace}`]?.address1.message}
          </span>
        )}
      </div>
      <div className="max-w-lg">
        <Label className="text-sm">Address 2</Label>
        <Controller
          name={`${namespace}.address2`}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              className="mt-1.5"
              onInput={(e: any) => hanldeChange(e)}
            />
          )}
        />
      </div>
      <div className="flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
        <div className="flex-1">
          <Label className="text-sm"><span className="text-red-500">*</span> City</Label>
          <Controller
            name={`${namespace}.city`}
            control={control}
            rules={{
              required: "City is Required",
            }}
            render={({ field }) => (
              <Input
                {...field}
                className="mt-1.5"
                onInput={(e: any) => hanldeChange(e)}
              />
            )}
          />
          {errors[`${namespace}`]?.city && (
            <span className="text-red-500">
              {errors[`${namespace}`]?.city.message}
            </span>
          )}
        </div>
        <div className="flex-1">
          <Label className="text-sm"><span className="text-red-500">*</span> State</Label>
          <Controller
            name={`${namespace}.state`}
            control={control}
            rules={{
              required: "State is Required",
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
              className="mt-1.5 input-focus-disable"
              options={stateOption}
              isSearchable
              value={stateOption.find((option) => option.value === value)}
                onChange={(selectedOption, e) => {
                  onChange(selectedOption ? selectedOption.value : "");
                  clearErrors("state");
                  hanldeChange({
                    target: {
                      name: "payment.state",
                      value: selectedOption?.value,
                    },
                  });
              }}
              onBlur={onBlur}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'transparent',
                  height: '48px',
                  overflow: 'auto',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: isDark==="dark"
                    ? state.isFocused
                      ? '#000000'  // Light gray for focus in dark mode
                      : '#374151'  // Dark background in dark mode
                    : state.isFocused
                    ? '#DEEBFF'   // Light blue for focus in light mode
                    : '#ffffff',  // **Ensure background is white in light mode**
                  color: isDark==="dark"
                    ? state.isSelected
                      ? '#ffffff' // Selected text color in dark mode
                      : '#f5f5f5'
                    : state.isSelected
                    ? '#000000' // **Ensure selected text is black in light mode**
                    : '#333333', // Default text color in light mode
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: isDark==="dark" ? 'white' : '#000000', // Selected value text color
                }),
              }}
              
              formatOptionLabel={formatOptionLabel}
            />
            
            )}
          />
          {errors[`${namespace}`]?.state && (
            <span className="text-red-500">
              {errors[`${namespace}`]?.state.message}
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
        <div className="flex-1">
          <Label className="text-sm"><span className="text-red-500">*</span> Country</Label>
          <Controller
            name={`${namespace}.country`}
            control={control}
            rules={{
              required: "Country is Required",
            }}
            render={({ field }) => (
              <Input
                {...field}
                className="mt-1.5"
                onInput={(e: any) => hanldeChange(e)}
              />
            )}
          />
          {errors[`${namespace}`]?.country && (
            <span className="text-red-500">
              {errors[`${namespace}`]?.country.message}
            </span>
          )}
        </div>
        <div className="flex-1">
          <Label className="text-sm"><span className="text-red-500">*</span> Zipcode</Label>
          <Controller
            name={`${namespace}.zip`}
            control={control}
            rules={{
              required: "Zipcode is Required",
            }}
            render={({ field }) => (
              <Input
                {...field}
                className="mt-1.5"
                onInput={(e: any) => {
                  e.target.value = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 6);
                  hanldeChange(e);
                }}
              />
            )}
          />
          {errors[`${namespace}`]?.zip && (
            <span className="text-red-500">
              {errors[`${namespace}`]?.zip.message}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default BillingAddressForm;