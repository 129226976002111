import React, { useRef, Dispatch, FC, SetStateAction } from "react";
import html2canvas from "html2canvas";
import Heading from "shared/Heading/Heading";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import TabFilters from "components/TabFilters";
import { Transition } from "@headlessui/react";
import { CategoryData, Product, menuSchedule } from "data/data";
import FullMenu from "views/FullMenu";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import TabCategoriesFilter from "./TabCategoriesFilter";
import { productImgs } from "contains/fakeData";
import {
  getMenuScheduleData,
  getOnlineOrderSettings,
} from "services/HomeService";
import MenuScheduleFilter from "./MenuScheduleFilter";
import { getAllCategoryNameData } from "services/HomeService";
import Page404 from "containers/Page404/Page404";
import axios from "axios";
import Cookies from "js-cookie";
export interface HeaderFilterSectionProps {
  className?: string;
  setItemData?: Dispatch<SetStateAction<Product[]>>;
  itemData?: Product[];
  renderAllData?: () => void;
  categoryData?: CategoryData[];
  menuScheduleData?: menuSchedule[];
  searchProduct?: any;
  getItemData?: any;
  setActivity?: any;
  imageUrl?: string;
  onlineOrderSettings:any;
  loading:boolean
}
// interface OnlineOrderSettings {
//   logo?: string;
//   orderLeadTime?: number;
//   image?: string;
//   titleText?: string;
//   customTextOne?: string;
//   pauseOnlineOrder?: boolean;
//   pauseOnlineOrderLabel?: string;
//   onlineDeliveryId?: string;
//   isDelivery?: boolean;

//   // Add other properties if necessary
// }
// interface ResponseData {
//   onlineOrderSettings: OnlineOrderSettings;
//   items: any[];
// }
const HeaderFilterSection: FC<HeaderFilterSectionProps> = ({
  className = "mb-12",
  setItemData,
  itemData,
  renderAllData,
  categoryData,
  menuScheduleData,
  searchProduct,
  getItemData,
  setActivity,
  imageUrl,

  onlineOrderSettings,
  loading,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [tabActive, setTabActive] = React.useState("All items");
  const navigate = useNavigate();
  const [showComponent, setShowComponent] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<
    menuSchedule[] | null
  >(null);
  // const MERCHANT_STORAGE_KEY = "merchantname";
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  // const merchantname = localStorage.getItem(MERCHANT_STORAGE_KEY);
  const [hasError, setHasError] = useState(false);
  // const [loading, setLoading] = useState(true);
  // const [onlineOrderSettings, setOnlineOrderSettings] =
  //   useState<OnlineOrderSettings | null>(null);

  // const LeadTime = localStorage.getItem("onlineOrderSettingsLeadTime");
  const isOpenNow = (fromTime: string, toTime: string): boolean => {
    const currentTime = new Date();

    // Convert time string (HH:mm) to hours and minutes
    const parseTime = (time: string): [number, number] => {
      if (!time) {
        return [0, 0]; // Default to 00:00 if time is null or empty
      }

      const [hours, minutes] = time.split(":").map(Number);
      return [hours, minutes];
    };

    // Parse fromTime and toTime as UTC
    const [fromHours, fromMinutes] = parseTime(fromTime);
    const [toHours, toMinutes] = parseTime(toTime);

    // Create Date objects in UTC
    const fromUTC = new Date();
    fromUTC.setUTCHours(fromHours, fromMinutes, 0, 0);

    const toUTC = new Date();
    toUTC.setUTCHours(toHours, toMinutes, 0, 0);

    // Convert UTC time to the local time zone
    const fromDateTime = new Date(
      fromUTC.toLocaleString("en-US", { timeZone: undefined })
    );
    const toDateTime = new Date(
      toUTC.toLocaleString("en-US", { timeZone: undefined })
    );

    // If the end time is before the start time, it means the period spans midnight
    if (toDateTime < fromDateTime) {
      // Check if current time is either after fromDateTime or before toDateTime
      return currentTime >= fromDateTime || currentTime <= toDateTime;
    }

    // Normal case where the end time is after the start time
    return currentTime >= fromDateTime && currentTime <= toDateTime;
  };

  const handleTabClick = (tab: string) => {
    setTabActive(tab);
    if (tab === "All items") {
      setActivity = true;
      renderAllData && renderAllData();
    }
  };
  const handlemenuSchedule = () => {
    if (menuScheduleData) {
      setSelectedSchedule(menuScheduleData);
      setModalOpen(true);
    }
  };
  const getCurrentDay = (): string => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDayIndex = new Date().getDay();
    return daysOfWeek[currentDayIndex];
  };

  const currentStatus = () => {
    const currentDay = getCurrentDay();
    if (!menuScheduleData) return null;

    for (const schedule of menuScheduleData) {
      if (schedule.menuDays === currentDay) {
        
        if (isOpenNow(schedule.fromTime, schedule.toTime)) {
          return { text: "Open", color: "#7BB542" };
        } else {
          return { text: "Closed", color: "red" };
        }
      }
    }

    // If no matching schedule for the current day
    return { text: "Closed", color: "red" };
  };

  const status = currentStatus();
  
 

  useEffect(() => {
    const MERCHANT_STORAGE_KEY = "merchantname";

    const merchantname = Cookies.get(MERCHANT_STORAGE_KEY);

    if (hasError === true) {
      navigate(`/${merchantname}/Page404`);
    }
  }, [hasError === true]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-500"></div>
      </div>
    );
  }
  return (
    <>
      
      <div className={` flex flex-col relative ${className}`}>
      {imageUrl && (
  <div className="relative w-full">
    {/* The image with rounded corners, dull effect, and fade-in animation */}
    <img
      src={onlineOrderSettings?.image}
      alt=""
      className="w-full object-cover rounded-lg animate-fade-in"
      style={{
        maxHeight: "30vh",
        objectFit: "cover",
        filter: "brightness(50%)", // Makes the image duller
      }}
    />
    {/* Overlaying the text with animations */}
    <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
      <h1 className="text-cream text-2xl sm:text-3xl font-bold slide-in-left">
        {onlineOrderSettings?.titleText}
      </h1>
      <p className="text-cream text-md sm:text-lg text-center mx-auto slide-in-left justify-center max-w-[80%]">
        {onlineOrderSettings?.customTextOne ?? "Default Text"}
      </p>
    </div>
  </div>
)}
      
        <div className="flex items-center space-x-2 mt-1">
          {/* Other elements like status and buttons */}
        </div>

        <div className="flex items-center space-x-2 mt-4 mb-2">
          <h3
            className="px-3 py-1 rounded-lg"
            style={{ backgroundColor: status?.color }}
          >
            {status?.text}
          </h3>
          <button
            className="bg-grey-500 dark:text-gray-100  px-3 py-1 rounded-lg focus:outline-black focus:ring focus:ring-grey"
            onClick={handlemenuSchedule}
          >
            Hours
          </button>
        </div>
        <div className="menu-title-row"></div>
        <div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
          <div className="flex gap-2 w-full">
            <TabCategoriesFilter
              categoryData={categoryData}
              setItemData={setItemData}
              renderAllData={renderAllData}
              searchProduct={searchProduct}
            />
          </div>
          <span className="block flex-shrink-0"></span>
        </div>
        <Transition
          show={isOpen}
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="w-full border-b "></div>
          <TabFilters
            getItemData={getItemData}
            categoryData={categoryData}
            itemData={itemData}
            setItemData={setItemData}
            renderAllData={renderAllData}
            isCategoryFilter={false}
          />
        </Transition>
      </div>

      {selectedSchedule && (
        <MenuScheduleFilter
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          menuschedule={selectedSchedule || []}
        />
      )}
    </>
  );
};

export default HeaderFilterSection;
