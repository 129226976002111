import React, { useState, useId, useRef, useEffect } from "react";
import Glide from "@glidejs/glide";
import { Category, TabCategoriesFiltersProps } from "data/data";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";

const TabCategoriesFilter: React.FC<TabCategoriesFiltersProps> = ({
  categoryData,
  setItemData,
  searchProduct,
}) => {
  const id = useId();
  const sliderRef = useRef(null);
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  const scrollRef = useRef<any>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [categoryItems, setCategoryItems] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );

  useEffect(() => {
    if (categoryData && categoryData.length > 0) {
      const filterCategory = categoryData.filter(
        (cat) => cat.items.length !== 0
      );
      const setAtoZItems = filterCategory.map((cat) => {
        const sortItems = [...cat.items].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        return {
          ...cat,
          items: sortItems,
        };
      });
      setCategoryItems(setAtoZItems);
    }
  }, [categoryData]);

  const filterCategoryData = async (category: any) => {
    try {
      const items = category.items;
      setSelectedCategory(category);
      setItemData && setItemData(items);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const filterCategory = categoryItems.filter(
      (item) => item.items.length > 0
    );
    const defaultselect = filterCategory[0];
    if (defaultselect && !searchProduct) {
      setSelectedCategory(defaultselect);
      filterCategoryData(defaultselect);
    } else if (searchProduct) {
      const combinedItems = filterCategory?.flatMap((item) =>
        item.items.filter((items: any) =>
          items.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
      const searchingCategory = filterCategory.filter((category) =>
        category.items.some((item: any) =>
          combinedItems.some((combinedItem) => combinedItem.name === item.name)
        )
      );
      const searchingItems = searchingCategory[0];
      setSelectedCategory(searchingItems);
      filterCategoryData(searchingItems);
    }
  }, [categoryItems, searchProduct]);

  useEffect(() => {
    if (!sliderRef.current) {
      return () => {};
    }

    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [sliderRef, UNIQUE_CLASS]);

  const handleRightArrowClick = () => {
    if (scrollRef.current) {
      const scrollWidth = scrollRef.current.scrollWidth;
      const containerWidth = scrollRef.current.clientWidth;
      const maxScrollLeft = scrollWidth - containerWidth;
      const newPosition = Math.min(scrollPosition + 100, maxScrollLeft); // Adjust the scrolling speed as needed
      setScrollPosition(newPosition);
      scrollRef.current.scrollTo({ left: newPosition, behavior: "smooth" });
      if (newPosition >= maxScrollLeft) {
        setShowRightArrow(false); // Hide the right arrow if there's no more content to scroll
      }
      setShowLeftArrow(true);
    }
  };

  const handleLeftArrowClick = () => {
    if (scrollRef.current) {
      const newPosition = Math.max(scrollPosition - 100, 0); // Adjust the scrolling speed as needed
      setScrollPosition(newPosition);
      scrollRef.current.scrollTo({ left: newPosition, behavior: "smooth" });

      // Check if there's still content to scroll
      if (newPosition <= 0) {
        setShowLeftArrow(false); // Hide the left arrow if there's no more content to scroll
      }
      setShowRightArrow(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (scrollRef.current) {
        setShowRightArrow(
          scrollRef.current.scrollWidth > scrollRef.current.clientWidth
        );
        setShowLeftArrow(scrollRef.current.scrollLeft > 0);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [scrollRef?.current?.scrollWidth, scrollRef?.current?.scrollLeft]);

  return (
    <div
      className="overflow-x-auto whitespace-nowrap flex py-2 items-center justify-between relative"
      ref={scrollRef}
    >
      {/* Left arrow */}
      {showLeftArrow && (
        <div
          className="sticky left-0 top-4 flex justify-center items-center bg-white h-[24px] w-[24px] pr-3  dark:bg-black dark:text-white"
          onClick={handleLeftArrowClick}
        >
          <AiOutlineArrowLeft />
        </div>
      )}
      {/* Render other categories */}
      {categoryItems?.map((category: Category) => (
        <div key={category.id}>
          <div
            className={`inline-block mx-3 cursor-pointer transition-colors duration-300 dark:text-gray-100  ${
              selectedCategory?.id === category.id
                ? "border-black text-black font-bold"
                : "text-neutral-700 font-semibold"
            } hover:bg-gray-200 p-1 hover:text-black dark:hover:text-black`}
            onClick={() => filterCategoryData(category)}
          >
            {category.name}
          </div>
        </div>
      ))}
      {showRightArrow && (
        <div
          className="sticky right-0 flex justify-center items-center top-4 bg-white h-[24px] w-[24px] pl-3  dark:bg-black dark:text-white"
          onClick={handleRightArrowClick}
        >
          <AiOutlineArrowRight />
        </div>
      )}
      {/* Right arrow */}
    </div>
  );
};

export default TabCategoriesFilter;
