
import React from 'react';

import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import visa from "images/vis.png";
import master from "images/mastercard.png";
interface PaymentFormProps {
  control: any; // Replace with the type for your form control
  errors: any; // Replace with the type for your form errors
  handleAddCard: () => void;
  // setValue:any;
  validateCardNumber: (value: string) => true | string; // Replace with your validation function type
  validateData: (value: string) => true | string;  // Replace with your validation function type
  setSelectedCard?:any
}

const PaymentForm: React.FC<PaymentFormProps> = ({
    control,
    errors,
   
    handleAddCard,
    validateCardNumber,
    validateData,
    setSelectedCard
}) => {
 
  const [cardType, setCardType] = useState<"Visa" | "MasterCard" | "">("");
 const cardTypeIcon = {
   Visa: visa,
   MasterCard: master,
  };
  const detectCardType = (cardNumber: string) => {
     const firstDigit = cardNumber.slice(0, 1);
     const firstTwoDigits = cardNumber.slice(0, 2);
     if (firstDigit === "4") return "Visa";
     if (["51", "52", "53", "54", "55"].includes(firstTwoDigits))
       return "MasterCard";
     return ""; // Default for unknown card type
   };
 
    return (
      <div className={`mt-6 mb-4 space-y-3 sm:space-y-5 `}>
        {/* <div className="max-w-lg">
          <Label className="text-sm">Card number</Label>
          <Controller
            name="cardNumber"
            control={control}
            rules={{
              required: "Card number is required",
              validate: validateCardNumber,
            }}
            render={({ field }) => (
              <Input
                {...field}
                className="mt-1.5"
                type="tel"
                onInput={(e: any) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                }}
              />
            )}
          />
          {errors.cardNumber && (
            <span className="text-red-500">{errors.cardNumber.message}</span>
          )}
        </div> */}
            <label
                          htmlFor="Credit-Card"
                          className="flex items-center space-x-4 sm:space-x-6"
                        >
                          <div className={`p-2.5 rounded-xl border-2 `}>
                            <svg
                              className="w-6 h-6 sm:w-7 sm:h-7"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M2 12.6101H19"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M19 10.28V17.43C18.97 20.28 18.19 21 15.22 21H5.78003C2.76003 21 2 20.2501 2 17.2701V10.28C2 7.58005 2.63 6.71005 5 6.57005C5.24 6.56005 5.50003 6.55005 5.78003 6.55005H15.22C18.24 6.55005 19 7.30005 19 10.28Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M22 6.73V13.72C22 16.42 21.37 17.29 19 17.43V10.28C19 7.3 18.24 6.55 15.22 6.55H5.78003C5.50003 6.55 5.24 6.56 5 6.57C5.03 3.72 5.81003 3 8.78003 3H18.22C21.24 3 22 3.75 22 6.73Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M5.25 17.8101H6.96997"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.10986 17.8101H12.5499"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <p className="font-medium">Debit / Credit Card</p>
                        </label>
        <div className="max-w-lg">
          <Label className="text-sm"><span className="text-red-500">*</span>Card Number</Label>
          <Controller
            name="cardNumber"
            control={control}
            rules={{
              required: "Card Number is required",
              validate: validateCardNumber,
            }}
            render={({ field }) => (
              <div className="relative">
                <Input
                  {...field}
                  className="mt-1.5 pr-10"
                  type="tel"
                  onInput={(e: any) => {
                    let value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                    field.onChange(value); // Update form value with raw digits (no spaces)
                    setSelectedCard(null)

                    // Format the value with spaces between every 4 digits
                    const formattedValue = value.replace(
                      /(\d{4})(?=\d)/g,
                      "$1 "
                    );
                    e.target.value = formattedValue;

                    const detectedCardType = detectCardType(value); // Detect card type
                    setCardType(detectedCardType); // Set card type for logo
                  }}
                />

                {/* Render the card logo */}
                {cardType && (
                  <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                    <img
                      src={cardTypeIcon[cardType]}
                      alt={`${cardType} logo`}
                      className="w-12"
                    />
                  </div>
                )}
              </div>
            )}
          />
          {errors.cardNumber && (
            <span className="text-red-500">{errors.cardNumber.message}</span>
          )}
        </div>
        <div className="flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
          <div className="flex-1">
            <Label className="text-sm"><span className="text-red-500">*</span>First Name</Label>
            <Controller
              name="firstname"
              control={control}
              rules={{
                required: "First Name is Required",
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  className="mt-1.5"
                  onInput={(e: any) => {
                    const value = e.target.value; 
                    setSelectedCard(null);      
                  }}
                />
              )}
            />
            {errors.firstname && (
              <span className="text-red-500">{errors.firstname.message}</span>
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm"><span className="text-red-500">*</span>Last Name</Label>
            <Controller
              name="lastname"
              control={control}
              rules={{
                required: "Last Name is Required",
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  className="mt-1.5"
                  onInput={(e: any) => {
                    const value = e.target.value; 
                    setSelectedCard(null);       
                  }}
                />
              )}
            />
            {errors.lastname && (
              <span className="text-red-500">{errors.lastname.message}</span>
            )}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
          <div className="sm:w-2/3">
            <Label className="text-sm"><span className="text-red-500">*</span>Expiration Date (MM/YY)</Label>
            <Controller
              name="expirationDate"
              control={control}
              rules={{
                required: "date is Required",
                validate: validateData,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  className="mt-1.5"
                  type="tel"
                  onInput={(e: any) => {
                    setSelectedCard(null)
                    let inputValue = e.target.value.replace(/\D/g, "");
                    if (inputValue.length === 1 && inputValue > 1) {
                      inputValue = "0" + inputValue;
                    }
                    if (inputValue.length >= 2) {
                      let month = inputValue.slice(0, 2);
                      if (parseInt(month, 10) > 12) {
                        month = "12";
                      }
                      e.target.value = `${month}/${inputValue.slice(2, 4)}`;
                    } else {
                      e.target.value = inputValue;
                    }
                  }}
                />
              )}
            />

            {errors.expirationDate && (
              <span className="text-red-500">
                {errors.expirationDate.message}
              </span>
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm"><span className="text-red-500">*</span>CVC</Label>
            <Controller
              name="CVC"
              control={control}
              rules={{
                validate: (values) => {
                  const cleaned = values.replace(/\D/g, "");
                  const valid = cleaned.length === 3 || cleaned.length === 4;
                  if (!valid) {
                    return "CVC is required";
                  }
                  return true;
                },
              }}
              render={({ field }) => (
                <Input
                  type="tel" // Change to "tel" to bring up the numeric keypad
                  {...field}
                  onChange={(e) => {
                    let value = e.target.value.replace(/\D/g, "");
                    if (value.length > 4) {
                      value = value.slice(0, 4);
                    }
                    field.onChange(value);
                  }}
                  className="mt-1"
                />
              )}
            />
            {errors.CVC && (
              <span className="text-red-500">{errors.CVC.message}</span>
            )}
          </div>
        </div>
      </div>
    );
};

export default PaymentForm