import { Controller, useFormContext } from "react-hook-form"; 
import Select from "react-select"; 
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { State } from "country-state-city";
import { useForm } from "react-hook-form";
import React, { useMemo, useState, useEffect } from "react";
import Checkbox from "shared/Checkbox/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Cookies from "js-cookie";
interface DeliveryAddressFormProps {
  control: any; 
  errors: any;
  setValue: any;
  clearErrors: any;
  namespace: any;
  
  isFormValue: any;

 
}

const DeliveryAddressForm: React.FC<DeliveryAddressFormProps> = ({
  control,
  errors,
  setValue,
  clearErrors,
  namespace,
 
  isFormValue,

 
}) => {
  const formatOptionLabel = ({ label, value }: any, { context }: any) => {
    return context === "value" ? value : label;
  };
  const [isChecked, setIsChecked] = useState(false);
  const AddressValue = JSON.parse(
    Cookies.get("BillingAddress") as any
  );
  const isDark =Cookies.get('theme')  
 
  const stateOption = useMemo(() => {
    return State.getAllStates()
      .filter((code: any) => code.countryCode === "US")
      .map((state: any) => ({
        label: state.name,
        value: state.isoCode,
      }));
  }, []);
  useEffect(() => {
    if (isChecked && AddressValue) {
      setValue(
        `${namespace}.address1`,
        AddressValue["payment.address1"] || AddressValue.address || ""
      );
      setValue(`${namespace}.address2`, AddressValue["payment.address2"]);
      setValue(
        `${namespace}.country`,
        AddressValue["payment.country"] || "USA" || AddressValue.country
      );
      setValue(
        `${namespace}.zip`,
        AddressValue["payment.zip"] || AddressValue.zip
      );
      setValue(
        `${namespace}.city`,
        AddressValue["payment.city"] || AddressValue.city
      );
      setValue(
        `${namespace}.state`,
        AddressValue["payment.state"] || AddressValue.state || ""
      );
    } else if (isFormValue) {
      return;
    } else {
      setValue(`${namespace}.address1`, "");
      setValue(`${namespace}.address2`, "");
      setValue(`${namespace}.country`, "USA");
      setValue(`${namespace}.zip`, "");
      setValue(`${namespace}.city`, "");
      setValue(`${namespace}.state`, "");
    }
  }, [isChecked, setValue, namespace]);
  
  return (
    <div className={`mt-6 mb-4 space-y-3 sm:space-y-5`}>
      <h1 className="text-1xl font-bold">Delivery Address</h1>
      <FormControlLabel
        control={
          <Checkbox
            name="Same as Billing Address"
            onChange={() => setIsChecked(!isChecked)}
          ></Checkbox>
        }
        label="same as Billing Address"
      />
      <div className="max-w-lg">
        <Label className="text-sm"><span className="text-red-500">*</span> Deliver To (Name)</Label>
        <Controller
          name={`${namespace}.deliverTo`}
          control={control}
          rules={{
            required: "Name is Required",
          }}
          render={({ field }) => (
            <Input
              {...field}
              className="mt-1.5 dark:text-gray-100"
              onInput={(e: any) => e.target.value}
            />
          )}
        />
        {errors[`${namespace}`]?.deliverTo && (
          <span className="text-red-500">
            {errors[`${namespace}`]?.deliverTo.message}
          </span>
        )}
      </div>
      <div className="max-w-lg">
        <Label className="text-sm"><span className="text-red-500">*</span>Contact Number</Label>
        <Controller
          name={`${namespace}.contact`}
          control={control}
          rules={{
            required: "Number is required",
            pattern: {
              value: /^[0-9]{10}$/,
              message: "Invalid phone number. Must be 10 digits.",
            },
          }}
          render={({ field }) => (
            <Input
              {...field}
              className="mt-1.5 dark:text-gray-100"
              onInput={(e: any) => e.target.value}
              placeholder="Enter 10-digit phone number"
            />
          )}
        />
        {errors[`${namespace}`]?.contact && (
          <span className="text-red-500">
            {errors[`${namespace}`]?.contact.message}
          </span>
        )}
      </div>

      <div className="max-w-lg">
        <Label className="text-sm"> <span className="text-red-500">*</span>Address 1</Label>
        <Controller
          name={`${namespace}.address1`}
          control={control}
          rules={{
            required: "Address is Required",
          }}
          render={({ field }) => (
            <Input
              {...field}
              className="mt-1.5 dark:text-gray-100"
              onInput={(e: any) => e.target.value}
              value={
                isChecked && AddressValue && !field.value
                  ? AddressValue["payment.address1"] ?? AddressValue.address
                  : field.value
              }

            
            />
          )}
        />
        {errors[`${namespace}`]?.address1 && (
          <span className="text-red-500">
            {errors[`${namespace}`]?.address1.message}
          </span>
        )}
      </div>
      <div className="max-w-lg">
        <Label className="text-sm">Address 2</Label>
        <Controller
          name={`${namespace}.address2`}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              className="mt-1.5 dark:text-gray-100"
              onInput={(e: any) => e.target.value}
              value={
                isChecked && AddressValue && !field.value
                  ? AddressValue["payment.address2"]
                  : field.value
              }
            />
          )}
        />
      </div>
      <div className="flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
        <div className="flex-1">
          <Label className="text-sm"><span className="text-red-500">*</span>City</Label>
          <Controller
            name={`${namespace}.city`}
            control={control}
            rules={{
              required: "City is Required",
            }}
            render={({ field }) => (
              <Input
                {...field}
                className="mt-1.5 dark:text-gray-100"
                onInput={(e: any) => e.target.value}
                value={
                  isChecked && AddressValue && !field.value
                    ? AddressValue["payment.city"] ?? AddressValue.city
                    : field.value
                 
                }
              />
            )}
          />
          {errors[`${namespace}`]?.city && (
            <span className="text-red-500">
              {errors[`${namespace}`]?.city.message}
            </span>
          )}
        </div>
        <div className="flex-1">
          <Label className="text-sm"><span className="text-red-500">*</span>State</Label>
          <Controller
            name={`${namespace}.state`}
            control={control}
            rules={{
              required: "State is Required",
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
              
                className="mt-1.5 dark:text-gray-100  input-focus-disable"
                options={stateOption}
                isSearchable
                value={stateOption.find((option) => option.value === value)}
                onChange={(selectedOption) => {
                  onChange(selectedOption ? selectedOption.value : ""); 
                  clearErrors("state"); 
                }}
                onBlur={onBlur}
                formatOptionLabel={formatOptionLabel}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: 'transparent',
                    height: '48px',
                    overflow: 'auto',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: isDark==="dark"
                      ? state.isFocused
                        ? '#000000'  
                        : '#374151' 
                      : state.isFocused
                      ? '#DEEBFF'   
                      : '#ffffff',  
                    color: isDark==="dark"
                      ? state.isSelected
                        ? '#ffffff' 
                        : '#f5f5f5'
                      : state.isSelected
                      ? '#000000' 
                      : '#333333', 
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: isDark==="dark" ? 'white' : '#000000', 
                  }),
                }}
              />
            )}
          />
          {errors[`${namespace}`]?.state && (
            <span className="text-red-500">
              {errors[`${namespace}`]?.state.message}
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
        <div className="flex-1">
          <Label className="text-sm"><span className="text-red-500">*</span>Country</Label>
          <Controller
            name={`${namespace}.country`}
            control={control}
            rules={{
              required: "Country is Required",
            }}
            render={({ field }) => (
              <Input
                {...field}
                className="mt-1.5 dark:text-gray-100  "
                onInput={(e: any) => e.target.value}
                
                value={
                  isChecked && AddressValue && !field.value
                    ? AddressValue["payment.country"] ?? AddressValue.country
                    : field.value
                }
                placeholder={field.value !== "" ? "USA" : ""}
              />
            )}
          />
          {errors[`${namespace}`]?.country && (
            <span className="text-red-500">
              {errors[`${namespace}`]?.country.message}
            </span>
          )}
        </div>
        <div className="flex-1">
          <Label className="text-sm"><span className="text-red-500">*</span>Zipcode</Label>
          <Controller
            name={`${namespace}.zip`}
            control={control}
            rules={{
              required: "Zipcode is Required",
            }}
            render={({ field }) => (
              <Input
                {...field}
                className="mt-1.5 dark:text-gray-100  "
                onInput={(e: any) => {
                  e.target.value = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 6);
                }}
                value={
                  isChecked && AddressValue && !field.value
                    ? AddressValue["payment.zip"] ?? AddressValue.zip
                    : field.value
                }
              />
            )}
          />
          {errors[`${namespace}`]?.zip && (
            <span className="text-red-500">
              {errors[`${namespace}`]?.zip.message}
            </span>
          )}
        </div>
      </div>
      <div className="max-w-lg">
        <Label className="text-sm">Delivery Instructions</Label>
        <Controller
          name={`${namespace}.deliveryInstructions`}
          control={control}
          
          render={({ field }) => (
            <Input
              {...field}
              className="mt-1.5 dark:text-gray-100  "
              maxLength={5000}
              onInput={(e: any) => e.target.value}
            />
          )}
        />
        
      </div>
    </div>
  );
};
export default DeliveryAddressForm;
