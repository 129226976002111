// import MyRouter from "routers/index";
import { ProductCartContextProvider } from "./contaxt/CartProductContext"
import { Helmet, HelmetProvider } from "react-helmet-async";
import MyRoutes from "routers/index";
import { useCallback, useEffect, useState } from "react";
import { getAllCategoryNameData } from "services/HomeService";
import Cookies from "js-cookie";
interface OnlineOrderSettings {
  logo?: string;
  orderLeadTime?: number;
  image?: string;
  titleText?: string;
  customTextOne?: string;
  pauseOnlineOrder?: boolean;
  pauseOnlineOrderLabel?: string;
  onlineDeliveryId?: string;
  isDelivery?: boolean;

  // Add other properties if necessary
}
interface ResponseData {
  onlineOrderSettings: OnlineOrderSettings;
  items: any[];
}
function App() {
 
  const title=Cookies.get("onlineOrderSettingsTitleText") ?? null;
  
  const [titletext,setTitletext]=useState(title);
   const [logo, setLogo] = useState<string | null>(null);
  console.log('logoo:1 ', logo);
  const isDark = Cookies.get("theme");
  const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  const handleSystemMode = useCallback(() => {
      if (mediaQuery.matches) {
        document.documentElement.classList.add("dark");
        document.documentElement.classList.remove("light");
      } else {
        document.documentElement.classList.add("light");
        document.documentElement.classList.remove("dark");
      }
  }, [isDark,mediaQuery]);
  const setFavicon = (imageUrl: any) => {
    const link = document.querySelector("link[rel='icon']");
    if (link && (link as HTMLLinkElement).href !== undefined) {
      (link as HTMLLinkElement).href = imageUrl;
    }
  };

   useEffect(() => {
      const fetchLogo = async () => {
        const response = await getAllCategoryNameData();
        const obtainedData = (response?.data as ResponseData)?.onlineOrderSettings;
        const onlineData = obtainedData as OnlineOrderSettings;
         if (onlineData?.logo) {
                  console.log("onlineData?.logo", onlineData?.logo);
                          Cookies.set("onlineOrderSettingsLogo", onlineData?.logo);
                        }
                        if (onlineData?.titleText) {
                          console.log("onlineData?.titleText", onlineData?.titleText);
                                  Cookies.set("onlineOrderSettingsTitleText", onlineData?.titleText);
                                }        
       
        const logoo = Cookies.get("onlineOrderSettingsLogo") ?? null;
        const titletext=Cookies.get("onlineOrderSettingsTitleText") ?? null;
        console.log("logoo:3 ", logoo,titletext);
        setLogo(logoo);
        setFavicon(logoo);
        setTitletext(titletext);
      };
  
      if (!titletext || !logo ) {
        const delay = setTimeout(() => {
          fetchLogo();
        },500); 
        return () => clearTimeout(delay); 
      }
    }, []);
 
  useEffect(() => {
    if (isDark === "system") {
      mediaQuery.addEventListener("change", handleSystemMode);
      handleSystemMode();
      return () => {
        mediaQuery.removeEventListener("change", handleSystemMode);
      };
    } else {
      if (isDark === "dark") {
        document.body.classList.add("dark");
        document.body.classList.remove("light");
      } else if (isDark === "light") {
        document.body.classList.add("light");
        document.body.classList.remove("dark");
      }
    }
  }, [isDark, handleSystemMode]);
  useEffect(() => {
    if (!Cookies.get("theme")) {
      Cookies.set("theme", "system");
      mediaQuery.addEventListener("change", handleSystemMode);
      handleSystemMode();
      return () => {
        mediaQuery.removeEventListener("change", handleSystemMode);
      };
    }
  }, []);

  return (
    <HelmetProvider>
    <Helmet>
      <meta charSet="UTF-8" />
      {/* <link rel="icon"  href="https://merchant-panel-imgs.s3.us-east-2.amazonaws.com/DEV_MERCHANT/OIG.OC.4.jpg" /> */}
      
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content="Basil POS Online Ordering" />
      
      {/* <link rel="icon" href={'src/images/ads.png'}></link> */}
      <title>{titletext}</title>
    </Helmet>
    <ProductCartContextProvider>
      {/* MAIN APP */}
      <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
        <MyRoutes />
      </div>
    </ProductCartContextProvider>
  </HelmetProvider>
  );
}

export default App;
